import React from "react";
import { SERVICES } from "../../data/services";

const ServicesPage = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full gap-10 p-20 phone:px-5">
      {SERVICES.map((service) => (
        <div className="w-[80%] flex flex-col justify-center items-center text-center py-8 px-10 bg-white/50 rounded hover:bg-white/80 select-none animate-fadeIn phone:w-[95%]">
          <span className="font-bold text-3xl">{service.name}</span>
          <span className="text-2xl">{service.description}</span>
        </div>
      ))}
    </div>
  );
};

export default ServicesPage;
