import React from "react";
import {
  PROFILE_LOCATION,
  PROFILE_NAME,
  PROFILE_SPECIALTY,
  PROFILE_TITLE,
} from "../../data/profile";

const ProfilePage = () => {
  return (
    <>
      <div className="flex-[6] flex justify-center items-center animate-fadeIn">
        <div className="flex flex-col justify-end items-center gap-8">
          <span className="text-5xl text-center max-w-96 text-white device:max-w-80">
            {PROFILE_NAME.toUpperCase()}
          </span>
          <span className="w-full h-[1px] bg-white"></span>
          <span className="text-xl text-white">
            {PROFILE_TITLE.charAt(0).toUpperCase() +
              PROFILE_TITLE.slice(1).toLowerCase()}
          </span>
          <span className="text-lg text-white">
            {PROFILE_SPECIALTY.toUpperCase()}
          </span>
          <span className="text-lg text-white">
            {PROFILE_LOCATION.toUpperCase()}
          </span>
        </div>
      </div>
      <div className="flex-[5] flex justify-start items-end device:hidden animate-fadeIn">
        <div className="h-[550px]">
          <img className="h-full" src="images/photo.png" alt="profile_photo" />
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
