import React from "react";
import { MENU } from "../../data/menu";
import { Outlet, useNavigate } from "react-router-dom";
import {
  EMAIL,
  EMAIL_SUBJECT,
  LINKEDIN_LINK,
  LINKEDIN_USER,
  PHONE_NUMBER,
} from "../../data/header";
import {
  IconAt,
  IconDeviceMobile,
  IconBrandLinkedin,
} from "@tabler/icons-react";

type HeaderItem = {
  value: string;
  icon: any;
  action?: () => void;
};

const HomePage = () => {
  const navigate = useNavigate();

  const mailtoLink = `mailto:${EMAIL}?subject=${encodeURIComponent(EMAIL_SUBJECT)}`;

  return (
    <div className="flex flex-col h-screen w-screen bg-black">
      <header className="flex-[1] bg-white min-h-20 max-h-20 flex justify-center items-center device:min-h-40 device:max-h-40">
        <div className="w-full flex flex-row items-center justify-around device:flex-col device:items-center device:justify gap-5">
          {[
            {
              value: PHONE_NUMBER,
              icon: <IconDeviceMobile size={18} />,
            },
            {
              value: LINKEDIN_USER,
              icon: <IconBrandLinkedin size={18} />,
              action: () => {
                window.open(LINKEDIN_LINK, "_blank");
              },
            },
            {
              value: EMAIL,
              icon: <IconAt size={18} />,
              action: () => {
                window.open(mailtoLink, "_blank");
              },
            },
          ].map((item: HeaderItem) => (
            <button
              className={`flex flex-row gap-3 justify-center items-center text-[#2d2d2f] hover:text-black ${item.action ? "cursor-pointer" : "cursor-default"}`}
              onClick={item.action}
            >
              {item.icon}
              <span className="text-sm">{item.value.toLowerCase()}</span>
            </button>
          ))}
        </div>
      </header>
      <main className="flex-[10] bg-[#bdc7c9] flex flex-col">
        <div className="w-full h-20 flex justify-center items-center gap-20 device:gap-8 phone:flex-wrap phone:gap-5 phone:gap-y-4 phone:h-24 phone:mt-7 phone:mb-0">
          {MENU.map((menuItem) => (
            <button
              className="text-sm text-[#2d2d2f] hover:text-black active:text-white phone:bg-white/40 phone:rounded phone:p-2 phone:active:bg-white/70 phone:active:text-black select-none"
              onClick={() => navigate(menuItem.path)}
            >
              {menuItem.name.toUpperCase()}
            </button>
          ))}
        </div>
        <div className="flex-[1] flex flex-row">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default HomePage;
