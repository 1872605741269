export enum AboutContent {
  DESCRIPTION,
  JOB_ITEMS,
}

export const ABOUT_DESCRIPTION =
  "Abogada con amplia experiencia en trámites empresariales y defensa de derechos fundamentales. Enfoque en temas de salud y servicios públicos. Ofrezco soluciones legales, personalizadas y eficientes para tus necesidades.";

export type AboutStudiesItem = {
  profesion: string;
  year: number;
  institution: string;
};

export const ABOUT_STUDIES: AboutStudiesItem[] = [
  {
    profesion: "Abogada",
    institution: "Pontificia Universidad Javeriana Cali",
    year: 2021,
  },
  {
    profesion: "Administradora de Empresas",
    institution: "Pontificia Universidad Javeriana Cali",
    year: 2025,
  },
];

export type JobItem = {
  title: string;
  content: string;
};

export const JOB_ITEMS: JobItem[] = [
  {
    title: "Actualización constante",
    content:
      "Nos encontramos al día con las novedades legislativas y jurisprudenciales",
  },
  {
    title: "Ética profesional",
    content: "Cumplimos con el Código de Ética y Disciplina del Abogado",
  },
  {
    title: "Conocimiento del marco legal",
    content: "Conocimiento profundo de la normatividad legal colombiana",
  },
  {
    title: "Orientación al cliente",
    content:
      "Nuestra prioridad es ayudarte ofrecemos asesoría personalizada y soluciones eficientes segun tus necesidades",
  },
  {
    title: "Eficiencia",
    content:
      "Servicio ágil y de calidad, cumpliendo con los plazos establecidos, segun tu disponibilidad y agendamiento",
  },
  {
    title: "Confidencialidad",
    content: "Garantizamos la protección de la información confidencial",
  },
];
