export type MenuItemType = {
  name: string;
  path: string;
};

export const MENU: MenuItemType[] = [
  {
    name: "Inicio",
    path: "",
  },
  {
    name: "Acerca de mi",
    path: "/acerca",
  },
  {
    name: "Como Trabajo",
    path: "/trabajo",
  },
  {
    name: "Servicios",
    path: "/servicios",
  },
];

export const MENU_MAP = {
  HOME: MENU[0],
  ABOUT: MENU[1],
  WORK: MENU[2],
  SERVICES: MENU[3],
};
