import React from "react";
import { ABOUT_DESCRIPTION, ABOUT_STUDIES } from "../../data/about";

const AboutPage = () => {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center">
      <div className="w-full flex-[2] flex flex-col justify-center items-center animate-fadeIn gap-10 py-5">
        <div className="w-[50%] flex-[4] flex justify-center items-center overflow-hidden">
          <span className="text-center text-2xl text-white drop-shadow-md py-5">
            {ABOUT_DESCRIPTION}
          </span>
        </div>
      </div>
      <div className="w-full flex-[2] animate-fadeIn flex justify-center items-start py-10 bg-[#2d2d2f]">
        <div className="w-[70%] flex flex-col justify-center items-center gap-14 text-white">
          <span className="text-4xl">Estudios</span>
          <div className="w-full flex flex-row justify-around items-center phone:flex-col phone:gap-10">
            {ABOUT_STUDIES.map((study) => (
              <div className="flex flex-col justify-center items-center gap-2 phone:gap-1">
                <div className="flex flex-col gap-2 justify-center items-center text-center">
                  <span className="text-2xl">{study.profesion}</span>
                  <span className="text-xl">{study.year}</span>
                </div>
                <span className="text-xl text-center">{study.institution}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
