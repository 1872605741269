import React, { useEffect, useRef, useState } from "react";
import { JOB_ITEMS } from "../../data/about";

const WorkPage = () => {
  const [displayedMessage, setDisplayedMessage] = useState<number>(0);
  const [manualChange, setManualChange] = useState<boolean>(false);

  const [draggingSlider, setDraggingSlider] = useState<boolean>(false);
  const [xAxisSlider, setXAxisSlider] = useState<number>(0);

  const sliderCardRef = useRef<HTMLDivElement>(null);

  document.addEventListener("mouseup", (event) => {
    setDraggingSlider(false);
  });

  document.addEventListener("touchend", (event) => {
    setDraggingSlider(false);
  });

  useEffect(() => {
    sliderCardRef.current?.addEventListener("mousedown", (event) => {
      setDraggingSlider(true);
    });

    sliderCardRef.current?.addEventListener("touchstart", (event) => {
      setDraggingSlider(true);
    });

    sliderCardRef.current?.addEventListener("mousemove", (event) => {
      const bounds = sliderCardRef.current?.getBoundingClientRect()!;
      const leftBound = bounds.left || 0;
      const boundsWidth = bounds.width;
      const middlePoint = leftBound + boundsWidth / 2;
      const differenceFromMiddle = event.clientX - middlePoint;
      const percentageOff = (100 / (boundsWidth / 2)) * differenceFromMiddle;
      setXAxisSlider(
        percentageOff > 0
          ? Math.min(100, percentageOff)
          : Math.max(-100, percentageOff),
      );
    });

    sliderCardRef.current?.addEventListener("touchmove", (event) => {
      const bounds = sliderCardRef.current?.getBoundingClientRect()!;
      const leftBound = bounds.left || 0;
      const boundsWidth = bounds.width;
      const middlePoint = leftBound + boundsWidth / 2;
      const differenceFromMiddle = event.touches[0].clientX - middlePoint;
      const percentageOff = (100 / (boundsWidth / 2)) * differenceFromMiddle;
      setXAxisSlider(
        percentageOff > 0
          ? Math.min(100, percentageOff)
          : Math.max(-100, percentageOff),
      );
    });
  }, [sliderCardRef.current]);

  useEffect(() => {
    if (draggingSlider) {
      if (xAxisSlider > 80) {
        setDisplayedMessage((current) => Math.max(0, current - 1));
        setDraggingSlider(false);
      } else if (xAxisSlider < -80) {
        setDisplayedMessage((current) =>
          Math.min(JOB_ITEMS.length - 1, current + 1),
        );
        setDraggingSlider(false);
      }
    }
  }, [xAxisSlider]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center animate-fadeIn gap-14 py-5">
      <div className="w-full flex justify-center items-center overflow-hidden">
        <div
          className="flex h-full flex-col justify-center item-center gap-10 select-none phone:w-[80%]"
          ref={sliderCardRef}
        >
          <div
            className="relative w-96 phone:w-full h-72 flex flex-col justify-center items-center text-center text-white transition-all duration-300 hover:drop-shadow-lg animate-fadeIn"
            key={JOB_ITEMS[displayedMessage].title}
            style={{
              transform: `translate(${draggingSlider ? xAxisSlider : 0}%, 0)`,
            }}
          >
            <span className="text-5xl device:text-4xl">
              {JOB_ITEMS[displayedMessage].title.toUpperCase()}
            </span>
            <span className="text-2xl">
              {JOB_ITEMS[displayedMessage].content}
            </span>
          </div>
          <div className="flex flex-row justify-center items-center gap-4">
            {JOB_ITEMS.map((item, index) => (
              <button
                className={`h-8 w-8 rounded-full transition-color duration-300 ${index == displayedMessage ? "bg-white" : "bg-black"}`}
                onClick={() => setDisplayedMessage(index)}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkPage;
