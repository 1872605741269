import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import ProfilePage from "./pages/profile";
import { MENU_MAP } from "./data/menu";
import AboutPage from "./pages/about";
import WorkPage from "./pages/work";
import ServicesPage from "./pages/services";

function App() {
  return (
    <Routes>
      <Route path="" element={<HomePage />}>
        <Route path="" element={<ProfilePage />} />
        <Route path={MENU_MAP.ABOUT.path} element={<AboutPage />} />
        <Route path={MENU_MAP.WORK.path} element={<WorkPage />} />
        <Route path={MENU_MAP.SERVICES.path} element={<ServicesPage />} />
      </Route>
      <Route path="*" element={<div>No encontrado</div>} />
    </Routes>
  );
}

export default App;
