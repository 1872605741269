export const SERVICES = [
  {
    name: "Elaboración de Tutelas para Protección de Derechos Fundamentales",
    description:
      "Asesoría y apoyo en la elaboración de acciones de tutela para proteger derechos fundamentales vulnerados. Acompañamiento en redacción, fundamentación y trámite de tutelas para obtener una respuesta judicial efectiva y oportuna.",
  },
  {
    name: "Asesoría en contratos comerciales",
    description:
      "Redacción, revisión y negociación de contratos de compraventa, distribución, franquicias, licencias, suministro y otros contratos comerciales.",
  },
  {
    name: "Constitución y estructuración de empresas",
    description:
      "Asesoramiento en la creación de empresas, elección de la forma jurídica adecuada, y estructuración de la organización corporativa.",
  },
];
